.element {
  overflow-y: auto;
}

.element::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
}

.li-items545 li {
  margin-bottom: 0px !important;
}

