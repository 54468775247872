.banner {
    background-color: white;
    padding: 0 5.5%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
}
.mainRightBg {
    background: #F8F8F8;
    /* padding: 0 0 0 15px; */
    border-radius: 30px 0 0 0;
    height: 100%;
}

.mainGrid{
    display: grid;
    grid-template-columns: 75% 25%;
    position: relative;
    height: 100%;
}

.gridLeft{
    display: flex; 
}

.gridRight{
    border-left: 1px solid lightgray;
    overflow-y: auto;
    max-height: 85vh;
}

@media (min-width: 1400px) {
    .gridLeft{
        justify-content: center;
    }
}
@media (max-width: 1200px) {
    .mainGrid{
        grid-template-columns: 70% 30%;
    }
}
@media (max-width: 900px) {
    .mainGrid{
        grid-template-columns: 100%;
    }
    .gridRight{
        display: none;
    }
}


