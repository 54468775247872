.Image {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Apply transition to both transform and box-shadow */
  /* Rest of your styles */
}

.Image:hover {
  transform: scale(1.05);
  /* Scale effect on hover */
  box-shadow: 0px 4px 8px rgba(45, 94, 255, 0.4);
  /* Corrected box-shadow syntax */
}


.FeatureCard {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Apply transition to both transform and box-shadow */
  /* Rest of your styles */
}

.FeatureCard:hover {
  transform: scale(1.05);
  /* Scale effect on hover */
  box-shadow: 0px 4px 8px rgba(45, 94, 255, 0.4);
  /* Corrected box-shadow syntax */
}

.myBG {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: "#E5E5E5";
  background-image: url('./line.png');

  opacity: 0.2,
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-on-scroll-d {
  animation: fadeInUp 2s ease-in-out forwards, fadeInUp 2s forwards;
}

/* SectionD.module.css */
.section {
  text-align: center;
  margin-top: 4rem;
  /* padding-bottom: 4rem; */
  background: #fbfbfd;
}

.button {
  background-color: white;
  color: #0262DF;
  border-radius: 25px;
  border: 1px solid #0262DF;
  padding-left: 4rem;
  padding-right: 4rem;
  font-weight: 550;
  box-shadow: none;
  margin-top: 6rem;
}

.heading {
  font-size: 45px;
  font-family: sans-serif, manrope;
  color: black;
  margin: 0;
  margin-bottom: 3rem;
}

.grid {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
  padding: 1rem;
}

.grid>div {
  height: auto;
  width: 29rem;
  /* border: 17px solid transparent; */
}

.textCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.ImgCon {
  /* border: 2px solid #0262DF !important; */
  /* background-color: #0262DF; */
  /* box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1); */
  border-radius: 12px;
  height: 100%;
  width: 100%;
  position: relative;
}

.ImgCon img {
  border-radius: 12px;
  height: 100%;
  width: 100%;
}

.item {
  /* padding: 1rem; */
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.subheading {
  font-size: 35px;
  font-family: sans-serif, manrope;
  color: black;
  text-align: start;
  margin: 0;
  line-height: 3rem;
}

.text {
  text-align: start;
  color: #475467;
}

.colored {
  color: #0262DF;
}

.image {
  width: 480px;
  height: 350px;
}


@media (max-width: 320px) {}

@media (max-width: 481px) {}

@media (max-width: 769px) {}

@media (max-width: 993px) {}

@media (min-width: 1201px) {
  .grid {
    gap: 3rem;
  }
}

@media (max-width: 1200px) {
  .grid {
    gap: 2rem;
  }

  .grid>div {
    width: 400px;
  }

  .heading {
    font-size: 40px;
  }

  .subheading {
    font-size: 35px;
  }

  .text {
    font-size: 15px;
  }

}

@media (max-width: 1000px) {
  .grid {
    margin-top: 4rem;
    gap: 1rem;
  }

  .heading {
    font-size: 35px;
  }

  .subheading {
    font-size: 30px;
    line-height: 2rem;
  }

  .text {
    font-size: 15px;
  }

  .grid>div {
    width: 350px;
    height: auto;
  }

  .grid {
    padding: 3rem;
  }
}

@media (max-width: 800px) {
  .heading {
    font-size: 28px;
  }

  .subheading {
    font-size: 22px;
  }

  .image {
    width: 250px;
  }

  .item {
    padding: 0.2rem;
  }

  .text {
    margin: 0;
  }


  .grid {
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .grid>div {
    width: auto;
    height: auto;
  }


}

@media (max-width: 600px) {
  .heading {
    font-size: 22px;
  }

  .subheading {
    font-size: 18px;
  }

  .image {
    width: 200px;
  }

  .item {
    padding: 0.2rem;
  }

  .grid {
    padding: 1rem 1rem;
  }

  .text {
    font-size: 13px;
  }

  .text br {
    display: none;
  }

}

@media (max-width: 480px) {
  .ImgCon img {
    border-radius: 12px;
    height: 100%;
    width: 80%;
  }

  .heading {
    font-size: 18px;
    text-align: center;
  }

  .subheading {
    font-size: 15px;
    text-align: center;
    line-height: 1.5rem;
  }

  .subheading br {
    display: none;
  }

  .image {
    width: 200px;
  }

  .item {
    padding: 0.2rem;
  }

  .grid {
    padding: 1rem;
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
  }

  .leftImgCon {
    display: flex;
    justify-content: center;
  }

  .rightImgCon {
    display: flex;
    justify-content: center;
  }

  .gridReverse {
    flex-direction: column-reverse !important;
  }

  .text {
    font-size: 13px;
    text-align: center;
  }

  .text br {
    display: none;
  }

}