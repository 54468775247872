.gradientBox {
    background-image: linear-gradient(to top, #0262df 0%, #04befe 100%);
}

.browseBtn {
    background: white !important;
    color: #0262DF !important;
    border-radius: 8px !important;
    padding: 0 !important;
    width: 13rem !important;
    height: 46px !important;
    font-size: 14px !important;
    font-family: sans-serif, manrope !important;
    font-weight: 550 !important;
    border: 1px solid #0262DF !important;
    cursor: pointer !important;
    text-transform: none !important;
}

.pitchDeckBtn {
    background: #0262DF !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    padding: 0 !important;
    width: 13rem !important;
    height: 46px !important;
    font-size: 14px !important;
    font-family: sans-serif, manrope !important;
    border-color: #0262DF !important;
    cursor: pointer !important;
    text-transform: none !important;
    font-weight: 550 !important;
}

.comingSoonBtn {
    background-color: #e6effc !important;
    color: #0262DF !important;
    border-radius: 25px !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    margin-bottom: 2rem !important;
    text-transform: none !important;
    font-weight: bold !important;
    box-shadow: none !important;
}

.comingSoonBtn:hover {
    background-color: #0262DF !important;
    color: white !important;
    box-shadow: none !important;
}

.howItWorksBtn {
    background-color: white !important;
    color: #0262DF !important;
    border-radius: 25px !important;
    border: 1px solid #0262DF !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    font-weight: 550 !important;
    box-shadow: none !important;
    margin-top: 5rem !important;
    margin-bottom: 1.6rem !important;
    transition: background-color 0.3s, color 0.3s !important;
}

.howItWorksBtn:hover {
    background-color: #0262DF !important;
    border: none !important;
    color: white !important;
    box-shadow: none !important;
    border: 1px solid transparent !important;
    /* background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important; */
}


@media (max-width: 1200px) {
    .browseBtn {
        width: 13rem !important;
        height: 50px !important;
    }

    .pitchDeckBtn {
        width: 13rem !important;
        height: 50px !important;
    }
}

@media (max-width: 800px) {
    .howItWorksBtn {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 3rem !important;
        font-size: 12px !important;
    }

    .browseBtn {
        width: 12rem !important;
        height: 44px !important;
    }

    .pitchDeckBtn {
        width: 12rem !important;
        height: 44px !important;
    }
}

@media (max-width: 600px) {
    .howItWorksBtn {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 3rem !important;
        font-size: 12px !important;
    }

    .browseBtn {
        width: 10rem !important;
        height: 38px !important;
        font-size: 12px !important;
    }

    .pitchDeckBtn {
        width: 10rem !important;
        height: 38px !important;
        font-size: 12px !important;
    }

    .comingSoonBtn {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        margin-bottom: 1rem !important;
        font-size: 13px !important;
    }
}

@media (max-width: 480px) {
    .browseBtn {
        width: 60% !important;
        height: 33px !important;
    }

    .pitchDeckBtn {
        width: 60% !important;
        height: 33px !important;
    }

    .howItWorksBtn {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
        margin-top: 1rem !important;
        font-size: 11px !important;
    }
}