.premiumBox {
    background-color: black;
    margin: 0 20px;
    margin-bottom: 15px;
    border-radius: 7px;
    padding: 8px 15px;
    color: white;
}

.premiumBox>h5 {
    font-weight: 600;
    text-align: center;
    margin: 0;
    cursor: default;
}

.premiumBox img {
    height: 18px;
}

.premiumBtn {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #0262DF;
    border-radius: 5px;
    margin-top: 10px;
    padding: 6px !important;
    cursor: pointer !important;
}

.premiumBtn:hover {
    /* background-color: #013d8b !important; */
}

.premiumBtn>span {
    background-image: none !important;
    font-family: "Manrope", Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
    position: relative !important;
    padding: initial !important;
    display: block !important;
    height: auto !important;
    line-height: normal !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-radius: 0 !important;
    transition-property: all !important;
    transition-duration: 0.3s !important;
}

.premiumBtn:hover>span {
    font-weight: 700 !important;
}

.premiumBtn>h5 {
    font-size: 12px;
    margin: 0;
}

