
.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
  
  .card {
    background: white;
    padding: 1.5rem;
    padding-top: 6.5rem;
    border-radius: 16px;
    width: fit-content;
    text-align: left;
    border: none;
    position: relative;
    width: 33rem;
  }
  
  .bgPopup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 9rem;
    background: #0262df;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-radius: 14px 14px 0 0;
  }
  
  .curveCon {
    position: relative;
    bottom: -1px;
    height: 2.5rem;
    width: 100%;
    background: white;
    border-radius: 50% 50% 0 0;
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.icon {
    /* height: 2rem; */
    width: 1.3rem;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.summaryBg h2 {
    margin-bottom: 20px;
    font-size: 17px;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
}

.summaryBg h3 {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
}

.iconCon{
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.para {
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.scroll {
    height: fit-content;
    overflow-y: auto;
    max-height: 28rem;
    padding-right: 5px;
    padding-top: 2px;
    margin-top: 3rem;
}

.scroll::-webkit-scrollbar {
    width: 10px;
}

.scroll::-webkit-scrollbar-track {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.015) -122%, #f3f3f3 95%);
    border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d23 90%);
    border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.738) -122%, #2d2d2d49 90%);
    border-radius: 7px;
}

.title {
    font-weight: 700;
    font-size: 30px;
    color: white !important;
}