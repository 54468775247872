/* Footer.module.css */
.footer {
    padding: 10px 0;
    border: 1px solid transparent;
    min-height: 7rem;
    display: grid;
    grid-template-columns: 27% 46% 27%;
    background-color: #FBFBFD;
}

.footer div:nth-child(1) {
    display: flex;
    justify-content: end;
    align-items: center;
}

.logoContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 12rem;
}

.logoImage {
    width: 200px;
    height: 35px;
}

.logoContainer p {
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.00938em;
    line-height: 1.5;
    margin: 0px;
    text-wrap: nowrap;
    margin-left: 24%;
}

.logoLink {
    cursor: pointer;
    text-decoration: none;
}


.footer nav:nth-child(2) {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
}

.footer div:nth-child(3) {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.navMenu a {
    color: black;
    font-family: 'manrope', sans-serif;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.authButtons {
    cursor: pointer;
    text-decoration: none;
}

.socialIcon {
    border-radius: 7px;
    background: #0262DF;
    height: 1.7rem;
    width: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialIcon svg {
    height: 1.2rem;
}

@media (max-width: 1200px) {
    .footer {
        grid-template-columns: 27% 46% 27%;
    }
}


@media (max-width: 1100px) {
    .footer nav:nth-child(2) {
        gap: 1rem;
    }
}

@media (max-width: 939px) {
    .footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .footer div:nth-child(1) {
        justify-content: center;
    }

    .footer nav:nth-child(2) {
        gap: 0.6rem;
    }

    .footer div:nth-child(3) {
        justify-content: center;
    }

    .navMenu a {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .logoImage {
        width: 186px;
        height: 32px;
    }

    .logoContainer {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 12rem;
    }

    .socialIcon {
        height: 1.5rem;
        width: 1.5rem;
    }
}

@media (max-width: 700px) {
    .logoImage {
        width: 141px;
        height: 32px;
    }

    .logoContainer {
        width: 7rem;
        align-items: center;
    }

    .logoContainer p {
        text-wrap: wrap;
        margin-left: 0;
    }

    .logoImage {
        width: 120px;
        height: 28px;
    }
}

@media (max-width: 600px) {
    .footer {
        flex-direction: column;
        gap: 1.5rem;
    }

    .logoImage {
        width: 186px;
        height: 32px;
    }

    .logoContainer {
        width: 15rem;
    }

    .logoContainer p {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .footer {
        gap: 1rem;
    }

    .logoContainer {
        gap: 10px;
    }

    .socialIcon {
        height: 1.3rem;
        width: 1.3rem;
    }

    .socialIcon svg {
        height: 0.9rem;
    }

    .linksContainer {
        gap: 10px;
    }

    .logoImage {
        width: 140px;
        height: 30px;
    }

    .navMenu a {
        font-size: 13px;
    }

    .footer nav:nth-child(2) {
        gap: 0.5rem;
    }

    .link {
        margin-right: 0;
    }


}