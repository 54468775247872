h1,
h2,
h3,
h4,
h5,
p {
    font-family: 'Manrope', sans-serif;
}

.banner {
    background-color: white;
    padding: 0 5.5%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* box-shadow: 0px 4px 12px rgb(0 0 0 / 7%); */
}
.mainRightBg {
    background: #F8F8F8;
    padding-top: 20px;
    border-radius: 30px 0 0 0;
    height: 100%;
}

.container {
    padding: 20px 6%;
    padding-bottom: 3rem;
    /* margin-top: -1.8rem; */
    /* border: 2px solid black; */
}

.innerCon {
    /* border: 2px solid blueviolet; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.title1 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 35px;
}

.text1 {
    margin: 0;
    text-align: center;
    font-size: 20px;
}

.title2 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 35px;
}

.text2 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    margin-bottom: 2rem;
}

.imageCon {}

.gradientText {
    font-weight: 550;
    color: #0262DF;
    font-size: inherit;
    font-family: sans-serif, 'manrope';
}

.con2 {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.7rem;
    margin: 3rem 0;
}

.con2>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lightTest {
    margin: 0;
    color: #A2A2A2;
    font-size: 20px;
}

.numHeading {
    margin: 0;
    font-size: 45px;
    font-weight: 700;
    line-height: 0rem;
}

.text3 {
    margin: 0;
    text-align: center;
    font-size: 20px;
}

.downloadBtn {
    background: none;
    border: none;
    border-radius: 10px;
    padding: 10px 30px;
    background-color: #0262DF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.3s;
    margin-top: 2rem;
}

.downloadBtn:hover {
    background-color: #024cad;
}

.downloadBtn img {
    height: 18px;
}

.downloadBtn h5 {
    font-family: 'Manrope', sans-serif;
    color: white;
    margin: 0;
    font-size: 20px;
}

.slider {
    height: 30rem;
    margin: 2.5rem 0;
    /* border: 2px solid blueviolet; */
}

.img {
    height: 100%;
}

.margin140 {
    margin: 14px 0;
}

.imagesCon {
    height: 30rem;
    margin: 3.5rem 0;
    width: 100%;
    display: grid;
    grid-template-columns: 17% 3% 60% 3% 17%;
}

.imgShadow{
    box-shadow: 0 0 6px -1px #8a8a8ab3;
}

.imagesCon>div {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    position: relative;
    /* margin-bottom: 10px; */
}

.imagesCon>div img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
}

@media (max-width: 1500px) {
    .container {
        padding: 1rem 7.7%;
    }

    .downloadBtn {
        padding: 10px 24px;
    }

    .downloadBtn img {
        height: 16px;
    }

    .downloadBtn h5 {
        font-size: 17px;
    }
}

@media (max-width: 1300px) {

    .slider,
    .imagesCon {
        height: 25rem;
    }
}

@media (max-width: 1200px) {
    .container {
        padding: 1rem 7%;
    }

    .slider,
    .imagesCon {
        height: 22rem;
    }
}

@media (max-width: 1100px) {
    .slider {}
}

@media (max-width: 1000px) {

    .slider,
    .imagesCon {
        height: 19rem;
    }

    .downloadBtn {
        padding: 11px 24px;
    }
}

@media (max-width: 900px) {

    .slider,
    .imagesCon {
        height: 15rem;
    }

    .title1 {
        font-size: 22px;
    }

    .text1 {
        font-size: 12px;
    }

    .title2 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .text2 {
        font-size: 12px;
    }

    .lightTest {
        font-size: 12px;
    }

    .text3 {
        font-size: 12px;
    }

    .numHeading {
        font-size: 34px;
    }

    .margin140 {
        margin: 8px 0;
    }

    .con2 {
        margin: 2rem 0;
    }
}

@media (max-width: 800px) {
    .slider {
        height: 12rem;
    }
}

@media (max-width: 700px) {

    .slider,
    .imagesCon {
        height: 9rem;
    }
}

@media (max-width: 600px) {
    .container {
        margin-top: 0;
    }
}

@media (max-width: 599px) {

    .slider,
    .imagesCon {
        height: 13rem;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 1rem 10%;
    }

    .slider,
    .imagesCon {
        height: 10rem;
    }
}

@media (max-width: 400px) {

    .slider,
    .imagesCon {
        height: 8rem;
    }

    .title1 {
        font-size: 17px;
    }

    .text1 {
        font-size: 11px;
    }

    .title2 {
        font-size: 17px;
        margin-bottom: 5px;
    }

    .text2 {
        font-size: 11px;
    }

    .lightTest {
        font-size: 11px;
    }

    .text3 {
        font-size: 11px;
    }

    .numHeading {
        font-size: 20px;
    }

    .margin140 {
        margin: 0;
    }

    .con2 {
        margin: 1rem 0;
        gap: 1rem;
    }

    .downloadBtn {
        padding: 7px 18px;
    }
}