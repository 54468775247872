:root {
    --black: black;
    --blue: #0262DF;
}

.gradientBox {
    background-image: linear-gradient(to top, #0262df 0%, #04befe 100%) !important;
}

.gradientBtnBlue {
    background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important;
}

.gradientBtnWhite {
    background: linear-gradient(to top, #d4d4d4 0%, #efefef 100%) !important;
}

.gradientTxtBlack {
    background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, rgb(72 72 72 / 89%) 0.89%, rgb(56 56 56) 17.23%, rgb(0 0 0 / 65%) 42.04%, rgb(0 0 0 / 79%) 55.12%, #333333 71.54%, #404040 100%);
}

.section {
    margin-top: 4rem;
}

.flexContainer {
    display: flex;
    justify-content: center;
}

.flexItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heading {
    font-size: 45px;
    font-family: sans-serif, manrope;
    color: black;
    margin-top: 0;
}

.pricingBtns {
    max-width: 310px;
    padding: 0.2rem;
    border-radius: 25px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    position: relative;
}

.pricingBtns button {
    /* background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important; */
}

.activeButton,
.inactiveButton {
    border-radius: 25px;
    font-weight: 600;
    cursor: pointer;
    padding: 0.5rem 2rem;
    border: 1px solid transparent;
    transition: background-color 0.3s, color 0.3s;
    /* Add transition for background-color and color */
}

.activeButton {
    /* border: none; */
    background: #0262DF;
    /* background: linear-gradient(to top, #0262df 0%, #00b1ed 100%) !important; */
    color: white;
}

.inactiveButton {
    background: transparent;
    color: black;
}

.saveImage {
    height: 3.5rem;
    position: absolute;
    right: -50px;
    top: -5px;
}

.gridContainer {
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.card {
    padding: 1.7rem 2rem 2rem 2rem;
    border-radius: 8px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0px 4px 12px #0362e038; */
    max-width: 320px;
    /* min-width: 200px; */
    width: 300px;
    /* height: max-content; */
    text-align: left;
    border: 1px solid;
    border-color: #0262DF;
}

.bgBlue {
    background: #0262DF;
}

.card1_p {
    margin: 0;
    margin-left: -1.5rem;
    font-size: 15px;
    font-weight: 500;
    /* line-height: 1rem; */
    color: var(--black);
}

.margin45 {
    margin-left: 0rem;
    margin-bottom: 4px;
}

.card2_p {
    margin: 0;
    margin-left: -1.5rem;
    font-size: 15px;
    font-weight: 500;
    /* line-height: 1rem; */
    color: white;
}

.planName {
    color: var(--black);
    font-weight: 550;
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
}

.price {
    font-size: 50px;
    margin-top: -0.2rem;
    color: var(--black);
    border-bottom: 1px solid var(--blue);
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}

.colorWhite {
    color: white;
}

.priceSuffix {
    font-size: 20px;
    font-weight: 400;
    margin-left: 7px;
}

.featureList {
    padding: 0;
    min-height: 9rem;
}

.featureList li {
    margin-bottom: 5px;
}

.featureIncluded {
    color: #0262DF;
    padding: 0.1rem;
}

.featureNotIncluded {
    color: rgb(176, 16, 16);
    padding: 0.1rem;
}

.buttonStyle {
    text-decoration: none;
    border: none;
    cursor: pointer;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #1976d2;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    margin-top: 20px !important;
    background-color: #0262DF !important;
    color: white !important;
    text-transform: none !important;
    width: 100% !important;
    border-radius: 7px !important;
    transition-property: all;
    transition-duration: 0.3s;
}

.buttonStyle:hover {
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 3px -8px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.buttonBlue {
    background-color: white !important;
    color: #0262DF !important;
}

@media (max-width: 1400px) {
    .buttonStyle {
        width: 100%;
    }

    .buttonStyle2 {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .heading {
        font-size: 40px;
    }
}

/* @media (max-width: 1100px) {
    .featureList{
        min-height: 10rem;
    }
} */
@media (max-width: 1100px) {
    .card {
        padding: 1.5rem 2rem 2rem 2rem;
    }

    .price {
        font-size: 40px;
        margin-top: -0.1rem;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }

    .buttonStyle {
        margin-top: 10px !important;
    }
}

/* @media (max-width:1030px) {
    .featureList{
        min-height: 11.5rem;
    }
} */
@media (max-width:1000px) {
    .card {
        max-width: 270px;
    }

    .gridContainer {
        flex-wrap: wrap;
    }

    .heading {
        font-size: 35px;
    }
}

@media (max-width: 800px) {
    .heading {
        font-size: 28px;
        margin: 0.5rem;
    }
}

@media (max-width: 600px) {
    .heading {
        font-size: 22px;
        margin: 0.5rem;
    }

    .planName {
        font-size: 1.2rem;
    }

    .price {
        font-size: 35px;
    }

    .card1_p {
        font-size: 14px;
    }

    .card2_p {
        font-size: 14px;
    }

    /* .featureList {
        min-height: 8rem;
    } */
}

@media (max-width: 480px) {
    .heading {
        font-size: 18px;
        margin-bottom: 1rem;
    }

    .section {
        margin-top: 1rem;
    }
}